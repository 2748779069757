import React from 'react'
import revolutionAnimation from './json-img/revolution.json';
import bulbAnimation from './json-img/bulb.json';
import Lottie from 'lottie-react';

function Chooseus() {
  return (
    <div>
      <div class="container-fluid-chooseus bg-primary feature pt-5">
        <div class="container pt-5">
            <div class="row g-5">
                <div class="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                    <div class="btn btn-sm border rounded-pill text-white px-3 mb-3">Why Choose Us</div>
                    <h1 class="text-white mb-4">Let's Shape the Future Together!</h1>
                    <p class="text-light mb-4">We believe in pushing the boundaries of technology to create innovative solutions that not only meet but exceed your expectations.</p>
                <div class="container-test">
                    <div class="logo-test">
                        <Lottie animationData={bulbAnimation} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/>
                    </div>
                    <div class="description-test">
                     <h5>Empowering Innovation</h5>
                     <span>At QFI, we don't just adapt to the future – we create it. Dive into a partnership where innovation leads the way!</span>
                 </div>
                </div>
                <div class="container-test">
                    <div class="logo-test">
                        <Lottie animationData={bulbAnimation} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/>
                    </div>
                    <div class="description-test">
                     <h5>Expert Minds at Your Service</h5>
                     <span>Meet your new think tank! We’re not just skilled, We’re eager to challenge the status quo and drive success directly to your doorstep.</span>
                 </div>
                </div>
                <div class="container-test">
                    <div class="logo-test">
                        <Lottie animationData={bulbAnimation} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/>
                    </div>
                    <div class="description-test">
                     <h5>Your Success Is Our Passion</h5>
                     <span> We're not just your IT service provider; we're your avid supporters, your tech enthusiasts, and your partners in growth.Choose QFI, where your success fuels our innovation.</span>
                 </div>
                </div>
                <div class="container-test">
                    <div class="logo-test">
                        <Lottie animationData={bulbAnimation} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/>
                    </div>
                    <div class="description-test">
                     <h5>Custom Solutions, Just for You</h5>
                     <span>Your vision, our expertise.From streamlining operations with AI to securing your data with next-gen cybersecurity, we ensure the technology works for you, not the other way around.</span>
                 </div>
                </div>
                <div class="container-test">
                    <div class="logo-test">
                        <Lottie animationData={bulbAnimation} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/>
                    </div>
                    <div class="description-test">
                     <h5>Ready to Start a Revolution?</h5>
                     <span>We believe in the power of innovation and the potential to transform the world. Connect with us today, and let's start crafting your success story. Innovation awaits – are you ready to meet it?</span>
                 </div>
                </div> 
                    
                    
                        
                </div>
                <div class="col-lg-6 align-self-end text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
                    <img class="img-fluid" src="img/feature-robot.png" alt=""></img>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Chooseus
