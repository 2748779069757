import React, { useState } from 'react';
import axios from 'axios';  
const ApiKey = process.env.API_KEY;
const PublicAccountID = process.env.PUBLIC_ACCOUNT_ID;
const ListName = process.env.LIST_NAME;
const templateID = process.env.TEMPLATE_ID; 
function Newsletter() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    if (!email) {
      setMessage('Please enter a valid email.');
      return;
    }
    console.log(ApiKey, PublicAccountID, ListName, templateID);
    //match list And validate email from our contact list
    try {
      // Step 1: Add to contact list
      await axios.get('https://api.elasticemail.com/v2/contact/add', {
        params: {
          publicAccountID: PublicAccountID,
          listName: ListName,
          email: email
        }
      });

      // // Step 2: Change status of contact to engaged
      await axios.get('https://api.elasticemail.com/v2/contact/changestatus', {
        params: {
          apikey: ApiKey,
          status: 'Active',
          emails: email
        }
      });

      // Step 3: Send mail to admin with subscription detail
      await axios.get('https://api.elasticemail.com/v2/email/send', {
        params: {
          apikey: ApiKey,
          subject: 'New Subscription',
          from: 'info@quantumfusioninfotech.in',
          template: templateID,
          to: 'ashish.ranjan@quantumfusioninfotech.in',
        }
      });

      setMessage('Subscription successful!');
    } catch (error) {
      setMessage('Failed to subscribe. Please try again.');
    }
  };

  return (
    <div className="container-fluid-newsletter bg-primary newsletter py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-md-5 ps-lg-0 pt-5 pt-md-0 text-start wow fadeIn" data-wow-delay="0.3s">
            <img className="img-fluid" src={`/feature1.png`} alt=""></img>
          </div>
          <div className="col-md-7 py-5 newsletter-text wow fadeIn" data-wow-delay="0.5s">
            <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">Newsletter</div>
            <h1 className="text-white mb-4">Let's subscribe to the newsletter</h1>
            <div className="position-relative w-100 mt-3">
              <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text"
                     placeholder="Enter Your Email" value={email} onChange={handleInputChange}></input>
              <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2" onClick={handleSubmit}>
                <i className="fa fa-paper-plane text-primary fs-4"></i>
              </button>
            </div>
            {message && <small className="text-white-50">{message}</small>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
