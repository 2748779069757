import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';

function Playlist({ apiKey, playlistId }) {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlaylist = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=10&playlistId=${playlistId}&key=${apiKey}`
        );
        const data = await response.json();
        setVideos(data.items || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching playlist:', error);
        setLoading(false);
      }
    };
    fetchPlaylist();
  }, [apiKey, playlistId]);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    draggable: true,
    arrows: true,
    nextArrow: <SampleArrow type="next" />,
    prevArrow: <SampleArrow type="prev" />
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="playlist-container ">
      <Slider {...settings}>
        {videos.length > 0 ? (
          videos.map((video) => (
            <div key={video.id} className="playlist-item">
              <h3>{video.snippet.title}</h3>
              <div className="video-container rounded-video">
                <iframe
                  src={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={video.snippet.title}
                  style={{ width: '100%', height: '100%' }}
                ></iframe>
              </div>
            </div>
          ))
        ) : (
          <div>No videos available in this playlist.</div>
        )}
      </Slider>
    </div>
  );
}

function SampleArrow({ type, onClick }) {
  const positionStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: '1',
    cursor: 'pointer',
    border: 'none',
  };
  const leftStyle = { ...positionStyle, left: '0', marginRight: '80px' };
  const rightStyle = { ...positionStyle, right: '0', marginLeft: '80px' };

  return (
    <button
      className={`carousel-arrow ${type === "prev" ? "carousel-arrow-prev" : "carousel-arrow-next"}`}
      onClick={onClick}
      style={type === "prev" ? leftStyle : rightStyle}
    >
      {type === "next" ? '>' : '<'}
    </button>
  );
}

export default Playlist;