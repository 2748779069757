import React from 'react'
import { Link } from 'react-router-dom'
import Lottie from 'lottie-react';
import linkedinAnimationData from './json-img/linkedin-animation.json';
import facebookAnimationData from './json-img/Animation-facebook.json';
import disAnimationData from './json-img/Animation-insta.json';

function About1() {
  return (
    <div>
       <div className="container-fluid py-5">
        <div className="container">
            <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className="about-img">
                        <img className="img-fluid" src="./img/about-img.jpg"></img>
                    </div>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div>
                    <h1 className="mb-4">Achieve Greater Insights with AI-Powered Business Intelligence</h1>
                    <p className="mb-4">
                        Experience unparalleled insights into your business operations with our AI-powered business intelligence solutions. Let yourself 
                        be future-ready with our innovative team & services for modern technologies.</p>
                    <p className="mb-4">
                        From predictive analytics to real-time data visualization, unlock the full potential of your business with our tailored cost-optimised 
                        cloud computing services, AI solutions and blockchain innovations in your desired domains.</p> 
                    <p className="mb-4">
                        Step ahead of the competition with our cutting-edge technology solutions.</p>
                    <div className="row g-3">
                        <div className="col-sm-6">
                            <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Award Winning</h6>
                            <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                        </div>
                        <div className="col-sm-6">
                            <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                            <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-4">
                        <Link className="btn btn-primary rounded-pill px-4 me-3" to={"/about"} target="_blank">Read More</Link>
                        
                        <Link className="btn btn-square-ani me-3 animation-icon" to="https://www.facebook.com/" target="_blank">
                            <Lottie animationData={facebookAnimationData} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/></Link>

                        <Link className="btn btn-square-ani me-3 animation-icon" to="https://www.linkedin.com/" target="_blank">
                            <Lottie animationData={linkedinAnimationData} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/></Link>
                        
                        <Link className="btn btn-square-ani me-3 animation-icon" to="https://www.instagram.com/" target="_blank">
                            <Lottie animationData={disAnimationData} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default About1
