import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import Hero1 from '../components/hero1'
import Search from '../components/search'
import About from '../components/about'
import Oursvc from '../components/oursvc'
import Chooseus from '../components/chooseus'
import Casestudy from '../components/casestudy'

import Newsletter from '../components/newsletter'

function home() {
  return (<div>
      <Header />
      
      <Hero1 />
      <Search />
      <About />
      <Oursvc />
      <Chooseus />
      <Casestudy />
      
      <Newsletter />
      <Footer />

    </div>
  )
}

export default home
