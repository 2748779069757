import React from 'react'
import Lottie from 'lottie-react'
import svcAnimation from './json-img/svc-animation.json'
import { Link } from 'react-router-dom';

function Oursvc() {

    const videoCard1 = [
        {   id: 1, 
            title: 'Comprehensive Full Stack Engineering1', 
            Url: 'https://youtu.be/MPtvbHfFOZU',
            description:'Create responsive, custom websites and apps with our full-stack development expertise that drives user engagement and business growth.', 
            Dic:<Lottie animationData={svcAnimation} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/>,
            dataDelay:'0.3s'
        },
        {   id: 2, 
            title: 'Automated Compliance Using Blockchain', 
            Url: 'https://youtu.be/MPtvbHfFOZU', 
            description:'Explore the potential of blockchain technology with our expertise in building secure and transparent applications. We help you leverage the power of blockchain for data security, traceability, and trust.', 
            Dic:<img src="./img/services-icon/blockchain.gif" alt="Automated Compliance Using Blockchain" style={{ width: '100px', height: '100px', borderRadius:'100px' }}/>,
            dataDelay:'0.5s'
        },
    ];
    const videoCard2 = [
        {   id: 3, 
            title: 'AI Fusions and Implementaions', 
            Url: 'https://youtu.be/MPtvbHfFOZU', 
            description:'Incorporate the transformative power of AI to gain valuable insights, automate processes, and personalize user experiences. We develop AI-powered solutions tailored to your specific business goals.', 
            Dic:<img src="./img/services-icon/ai-mind.gif" alt="" style={{ width: '100px', height: '100px', borderRadius:'100px' }} />,
            dataDelay:'0.7s'
        },
        {   id: 4, 
            title: 'Hybrid-Cloud Infrastructure Solutions', 
            Url: 'https://youtu.be/MPtvbHfFOZU', 
            description:'Leverage the power of cloud technology with our expertise in cloud migration, infrastructure management, and application deployment. We optimize your cloud environment for cost-efficiency and scalability.', 
            Dic:<img src="./img/services-icon/cloud-computing-security.gif" alt="" style={{ width: '100px', height: '100px', borderRadius:'100px' }} />,
            dataDelay:'1.0s'
        },
       
      ];
  return (
    <div>
       <div className="container-fluid bg-light mt-5 py-5">
            <div className="container py-5">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                        <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Services</div>
                        <h1 className="mb-4">Unleash the Power of Innovation with Trending Technologies: Complete IT Solutions for Your Business</h1>
                        <p className="mb-4"> At QFI, We leverage cloud computing's scalability and security for robust infrastructure, build user-friendly applications with  full-stack development, implement AI for intelligent automation and data analysis, and integrate blockchain for secure, transparent systems.This comprehensive approach allows us to tailor solutions that drive business growth across various technological landscapes.</p>
                        <Link className="btn btn-primary rounded-pill px-4"  to={'/svc'} target='_blank'>Read More</Link>
                    </div>

                    <div className="col-lg-7">
                        <div className="row g-4">
                            <div className="col-md-6">
                                <div className="row g-4">
                                    {videoCard1.map(vc => (
                                        <div key={vc.id} className="col-12 wow fadeIn" data-wow-delay={vc.dataDelay}>
                                            <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                    {vc.Dic}
                                                </div>
                                                <h5 className="mb-3">{vc.title}</h5>
                                                <p>{vc.description}</p>
                                                <Link className="btn px-3 mt-auto mx-auto"  to={`/video/${vc.id}`} target='_blank'>Read More</Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="col-md-6 pt-md-4">
                                <div className="row g-4">
                                    {videoCard2.map(vc => (
                                        <div key={vc.id} className="col-12 wow fadeIn" data-wow-delay={vc.dataDelay}>
                                            <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                                                <div className="service-icon btn-square">
                                                    {vc.Dic}
                                                </div>
                                                <h5 className="mb-3">{vc.title}</h5>
                                                <p>{vc.description}</p>
                                                <Link className="btn px-3 mt-auto mx-auto"  to={`/video/${vc.id}`} target='_blank'>Read More</Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Oursvc
