import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import Hero1 from '../components/hero1'
import Search from '../components/search'
import About from '../components/about'
import Oursvc from '../components/oursvc'
import Chooseus from '../components/chooseus'
import Casestudy from '../components/casestudy'
import Faq from '../components/faq'
import Team from '../components/team'
import Testimonial from '../components/Testimonial'
import Newsletter from '../components/newsletter'
import { Link } from 'react-router-dom';
function Faqs() {
  return (
  <div>
      <Header />
      <div class="container-fluid pt-5 bg-primary hero-header">
        <div class="container pt-5">
            <div class="row g-5 pt-5">
                <div class="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                    <h1 class="display-4 text-white mb-4 animated slideInRight">FAQs</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center justify-content-lg-start mb-0">
                        <li class="breadcrumb-item"><Link class="text-white" to="/">Home</Link></li>
                          <li class="breadcrumb-item"><Link class="text-white" to="/">Pages</Link></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">FAQs</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-lg-6 align-self-end text-center text-lg-end">
                    <img class="img-fluid faq-page" src="img/hero-img.png" alt="" ></img>
                </div>
            </div>
        </div>
    </div>
      <Search />
      
      <Faq />
      
      
      <Newsletter />
      <Footer />

    </div>
  )
}

export default Faqs
