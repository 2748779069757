import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import Hero1 from '../components/hero1'
import Search from '../components/search'
import About from '../components/about'
import Oursvc from '../components/oursvc'
import Chooseus from '../components/chooseus'
import Casestudy from '../components/casestudy'
import Faq from '../components/faq'
import Team from '../components/team'
import Testimonial from '../components/Testimonial'
import Newsletter from '../components/newsletter'
import { Link } from 'react-router-dom';
function home() {
  return (<div>
      <Header />
      <div class="container-fluid pt-5 bg-primary hero-header">
        <div class="container pt-5">
            <div class="row g-5 pt-5">
                <div class="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                    <h1 class="display-4 text-white mb-4 animated slideInRight">404 Error</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center justify-content-lg-start mb-0">
                           <li class="breadcrumb-item"><Link class="text-white" to="/">Home</Link></li>
                           <li class="breadcrumb-item"><Link class="text-white" to="/">Pages</Link></li>
                           <li class="breadcrumb-item text-white active" aria-current="page">404 Error</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-lg-6 align-self-end text-center text-lg-end">
                    <img class="img-fluid fof-page" src="img/hero-img.png" alt=""></img>
                </div>
            </div>
        </div>
    </div>
      <Search />
      <div class="container-fluid py-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container text-center py-5">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
                    <h1 class="display-1">404</h1>
                    <h1 class="mb-4">Page Not Found</h1>
                    <p class="mb-4">We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</p>
                    <a class="btn btn-primary rounded-pill py-3 px-5" href="">Go Back To Home</a>
                </div>
            </div>
        </div>
    </div>
      <Newsletter />
      <Footer />

    </div>
  )
}

export default home
