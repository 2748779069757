import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';

function DocPlaylist({ apiKey, folderId }) {
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents&key=${apiKey}`
        );
        const data = await response.json();
        setDocs(data.files || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching documents:', error);
        setLoading(false);
      }
    };
    fetchDocuments();
  }, [apiKey, folderId]);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    draggable: true,
    arrows: true,
    nextArrow: <SampleArrow type="next" />,
    prevArrow: <SampleArrow type="prev" />
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="playlist-container">
      <Slider {...settings}>
        {docs.length > 0 ? (
          docs.map((doc) => (
            <div key={doc.id} className="playlist-item">
              <h3>{doc.name}</h3>
              <div className="doc-container rounded-doc">
                
                  src={`https://drive.google.com/file/d/${doc.id}/preview`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={doc.name}
                  style={{ width: '100%', height: '100%' }}
                
              </div>
            </div>
          ))
        ) : (
          <div>No documents available in this folder.</div>
        )}
      </Slider>
    </div>
  );
}

function SampleArrow({ type, onClick }) {
  const positionStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: '1',
    cursor: 'pointer',
    border: 'none',
  };
  const leftStyle = { ...positionStyle, left: '0', marginRight: '80px' };
  const rightStyle = { ...positionStyle, right: '0', marginLeft: '80px' };

  return (
    <button
      className={`carousel-arrow ${type === "prev" ? "carousel-arrow-prev" : "carousel-arrow-next"}`}
      onClick={onClick}
      style={type === "prev" ? leftStyle : rightStyle}
    >
      {type === "next" ? '>' : '<'}
    </button>
  );
}

export default DocPlaylist;
