import React from 'react';
import Playlist from './playlist'; // Make sure to adjust the import path accordingly
import Header from '../components/header'
import Search from '../components/search'
import Newsletter from '../components/newsletter' 
import Footer from '../components/footer'
import { useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import svcAnimation from './json-img/svc-animation.json'
import { Link } from 'react-router-dom';

function Video() {
  const apiKey = 'AIzaSyAVvHzX0s6JIdkpBWKwj5yzgOy1y403ukk'; // Replace with your actual API key
  const playlistId = 'PLPro2BiiE1kDw5r0zVM3YLaz0oLonVTuE'; // Replace with your actual playlist ID


  const { id } = useParams();
  

  const videoCard = [
    {   id: 1, 
        title: 'Comprehensive Full Stack Engineering1', 
        Url: 'https://youtu.be/MPtvbHfFOZU',
        description:'Create responsive, custom websites and apps with our full-stack development expertise that drives user engagement and business growth.', 
        Dic:<Lottie animationData={svcAnimation} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/>,
        dataDelay:'0.3s'
    },
    {   id: 2, 
        title: 'Automated Compliance Using Blockchain', 
        Url: 'https://youtu.be/MPtvbHfFOZU', 
        description:'Explore the potential of blockchain technology with our expertise in building secure and transparent applications. We help you leverage the power of blockchain for data security, traceability, and trust.', 
        Dic:<img src="./img/services-icon/blockchain.gif" alt="Automated Compliance Using Blockchain" style={{ width: '100px', height: '100px', borderRadius:'100px' }}/>,
        dataDelay:'0.5s'
    },
    {   id: 3, 
        title: 'AI Fusions and Implementaions', 
        Url: 'https://youtu.be/MPtvbHfFOZU', 
        description:'Incorporate the transformative power of AI to gain valuable insights, automate processes, and personalize user experiences. We develop AI-powered solutions tailored to your specific business goals.', 
        Dic:<img src="./img/services-icon/ai-mind.gif" alt="" style={{ width: '100px', height: '100px', borderRadius:'100px' }} />,
        dataDelay:'0.7s'
    },
    {   id: 4, 
        title: 'Hybrid-Cloud Infrastructure Solutions', 
        Url: 'https://youtu.be/MPtvbHfFOZU', 
        description:'Leverage the power of cloud technology with our expertise in cloud migration, infrastructure management, and application deployment. We optimize your cloud environment for cost-efficiency and scalability.', 
        Dic:<img src="./img/services-icon/cloud-computing-security.gif" alt="" style={{ width: '100px', height: '100px', borderRadius:'100px' }} />,
        dataDelay:'1.0s'
    },
   
  ];
  const vc = videoCard.find(vc => vc.id === parseInt(id));

  return (
    <div>
      <Header/>
      
       <div class="container-fluid pt-5 bg-primary hero-header">
        <div class="container pt-5">
            <div class="row g-5 pt-5">
                <div class="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                    <h1 class="display-4 text-white mb-4 animated slideInRight">Services</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center justify-content-lg-start mb-0">
                            <li class="breadcrumb-item"><Link class="text-white" to="/">Home</Link></li>
                            <li class="breadcrumb-item"><Link class="text-white" to="/">Pages</Link></li>
                            <li class="breadcrumb-item"><Link class="text-white" to="/svc">Our Services</Link></li>
                            <li class="breadcrumb-item text-white active" aria-current="page">Services</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-lg-6 align-self-end text-center text-lg-end">
                    <img class="img-fluid service-page" src="img/hero-img.png" alt="" ></img>
                </div>
            </div>
        </div>
    </div>
    <Search />

      <h1>{vc.title}</h1>
      <h2>{vc.description}</h2>
      <p>
        Published on: <time dateTime="2024-04-19">April 19, 2024</time>
      </p>

      <div className="container-vid">
        <div className="video-container">
           <iframe
            className="rounded-video"
            width="640"
            height="360"
            src={vc.Url.replace('youtu.be', 'www.youtube.com/embed')}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded video"
          ></iframe>
        </div>
      </div>
    <div>
      <h2>Description</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum</p>
    </div>
      {/* Playlist Section */}
      <Playlist apiKey={apiKey} playlistId={playlistId} />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Video;
