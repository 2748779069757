import React from 'react';
import { Link } from 'react-router-dom';

function Casestudy() {
    const caseStudies = [
        { id: 1, title: 'Robotic Automation', img: 'img/project-1.jpg', description: 'Lorem elitr magna stet eirmod labore amet labore clita' },
        { id: 2, title: 'Machine Learning', img: 'img/project-2.jpg', description: 'Lorem elitr magna stet eirmod labore amet labore clita' },
        { id: 3, title: 'Predictive Analysis', img: 'img/project-3.jpg', description: 'Lorem elitr magna stet eirmod labore amet labore clita' },
      ];
      
  return (
    <div>
       <div className="container-fluid bg-light py-5">
      <div className="container py-5">
        <div className="mx-auto text-center wow fadeIn casestudy" data-wow-delay="0.1s">
          <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Case Study</div>
          <h1 className="mb-4">Explore Our Recent Case Studies</h1>
        </div>
        <div className="row g-4">
          {caseStudies.map(cs => (
            <div key={cs.id} className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src={cs.img} alt={cs.title}></img>
                <Link className="case-overlay text-decoration-none" to={`/case-study/${cs.id}`} target='_blank'>
                  <small>{cs.title}</small>
                  <h5 className="lh-base text-white mb-3">{cs.description}</h5>
                  <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
}

export default Casestudy;