import React from 'react';
import DocPlaylist from './docPlaylist';
import Header from '../components/header'
import Search from '../components/search'
import Newsletter from '../components/newsletter' 
import Footer from '../components/footer'
import { useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
function CaseStudyDetail() {
  const { id } = useParams();
  
  const caseStudies = [
    { id: 1, title: 'Robotic Automation', docUrl: 'https://drive.google.com/file/d/1vu_LfmGC8EoOUTq0-HK-3Wt47La3Lq8w/view', relatedDocs: ['https://drive.google.com/drive/u/2/folders/19xlnBj8vzHqDvqVTG7pWCLK0YVdvGy3g']},
    { id: 2, title: 'Machine Learning', docUrl: 'https://drive.google.com/file/d/4/view', relatedDocs: ['https://drive.google.com/file/d/5/view', 'https://drive.google.com/file/d/6/view'] },
    { id: 3, title: 'Predictive Analysis', docUrl: 'https://drive.google.com/file/d/7/view', relatedDocs: ['https://drive.google.com/file/d/8/view', 'https://drive.google.com/file/d/9/view'] },
  ];

  const caseStudy = caseStudies.find(cs => cs.id === parseInt(id));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const apiKey = 'AIzaSyAVvHzX0s6JIdkpBWKwj5yzgOy1y403ukk';
  const folderId = 'PLPro2BiiE1kDw5r0zVM3YLaz0oLonVTuE';

  return (
    <div>
    <Header />
    <div class="container-fluid pt-5 bg-primary hero-header">
        <div class="container pt-5">
            <div class="row g-5 pt-5">
                <div class="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                    <h1 class="display-4 text-white mb-4 animated slideInRight">Our Projects</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center justify-content-lg-start mb-0">
                          <li class="breadcrumb-item"><Link class="text-white" to="/">Home</Link></li>
                          <li class="breadcrumb-item"><Link class="text-white" to="/">Pages</Link></li>
                          <li class="breadcrumb-item"><Link class="text-white" to="/case-study">Our Projects</Link></li>
                          <li class="breadcrumb-item text-white active" aria-current="page">Case Studies</li>
                            
                        </ol>
                    </nav>
                </div>
                <div class="col-lg-6 align-self-end text-center text-lg-end">
                    <img class="img-fluid projects-page" src="img/hero-img.png" alt=""></img>
                </div>
            </div>
        </div>
    </div>
    <Search />
      
      <div className="container py-5">
          <div className="text-center">
            <h1>{caseStudy.title}</h1>
            <iframe src={caseStudy.docUrl} width="100%" height="600px" title="document-viewer"></iframe>
          </div>
          
      </div>
       <div className="mt-5">
            <h2>Related Documents</h2><br></br>
            <DocPlaylist apiKey={apiKey} folderId={folderId} />
       </div>
      <Newsletter />
      <Footer />
    </div>
  );
}

export default CaseStudyDetail;
