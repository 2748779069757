import React from 'react'
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import linkedinAnimationData from './json-img/linkedin-animation.json';
import facebookAnimationData from './json-img/Animation-facebook.json';
import disAnimationData from './json-img/Animation-insta.json';

function Footer() {
    return (
        <div>
            <div className="container-fluid bg-dark text-white-50 footer pt-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                            <Link to="/"  target="_blank" className="footer-logo-brand">
                                <img src={`/logo1-removebg-preview.png`} alt="Your Logo"></img>
                            </Link>
                            <p className="mb-0 hover-footer-text">Experience the future with our AI-powered solutions. At Quantum Fusion InfoTech, We offer seamless technology integration for enhanced performance and growth. Partner with our certified professionals to reach new heights. Join us in embracing the future of IT and innovation.</p>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn get-in-touch" data-wow-delay="0.3s">
                            <h5 className="text-white mb-4">Get In Touch</h5>
                            <p><i className="fa fa-map-marker-alt me-3"></i>Patna, Bihar, India</p>
                            <p><i className="fa fa-phone-alt me-3"></i>+91 8709940857</p>
                            <p><i className="fa fa-envelope me-3"></i>info@quantumfusioninfotech.in</p>
                            <div className="d-flex pt-2 ">
                                {/* <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a>
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-facebook-f"></i></a> 
                        <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-youtube"></i></a>  */}
                                <Link className="btn btn-square-ani me-3 animation-icon" to="https://www.facebook.com/" target="_blank">
                            <Lottie animationData={facebookAnimationData} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/></Link>

                        <Link className="btn btn-square-ani me-3 animation-icon" to="https://www.linkedin.com/" target="_blank">
                            <Lottie animationData={linkedinAnimationData} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/></Link>
                        
                        <Link className="btn btn-square-ani me-3 animation-icon" to="https://www.instagram.com/" target="_blank">
                            <Lottie animationData={disAnimationData} loop={true} autoplay={true} speed={1.5} style={{ width: '200%', height: '100%' }}/></Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                            <h5 className="text-white mb-4">Popular Link</h5>
                            <Link to="/about" target='_blank' className="btn btn-link" >About Us</Link>
                            <Link to="/contact-us" target='_blank' className="btn btn-link" >Contact Us</Link>
                            <Link to="/privacy-policy" target='_blank' className="btn btn-link">Privacy Policy</Link>
                            <Link to="/term-conditions" target='_blank' className="btn btn-link">Terms & Condition</Link>
                            {/* <a className="btn btn-link" href="404.html">Career</a> */}
                        </div>
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                            <h5 className="text-white mb-4">Our Services</h5>
                            <Link to="/video/1" target="_blank" className="btn btn-link">Robotic Automation</Link>
                            <a className="btn btn-link" href="">Machine learning</a>
                            <a className="btn btn-link" href="">Predictive Analysis</a>
                            <a className="btn btn-link" href="">Data Science</a>
                            <a className="btn btn-link" href="">Robot Technology</a>
                        </div>
                    </div>
                </div>
                <div className="container wow fadeIn" data-wow-delay="0.1s">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <Link className="border-bottom" to="/"  target="_blank">Quantum Fusion InfoTech</Link>, All Right Reserved.
                                Designed By <Link className="border-bottom"to="/"  target="_blank">Quantum Fusion InfoTech</Link>
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                <div className="footer-menu">
                                    <Link to="/"  target="_blank">Home</Link>
                                    {/* <a href="">Cookies</a> */}
                                    <Link to="/F0F"  target="_blank">Help</Link>
                                    <Link to="/faqs"  target="_blank">FAQs</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>

                <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top pt-2"><i className="bi bi-arrow-up"></i></a>

            </div>
        </div>

    );
}

export default Footer;
