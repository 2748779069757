
import Home from './pages/home.js';
import { BrowserRouter ,Routes,Route } from 'react-router-dom';
import Service from './pages/Service.js';
import Projects from './pages/projects.js';
import Teamview from './pages/teamview';
import Faqs from './pages/faqs.js';
import Contact from './pages/contact.js'; 
import About from './pages/about.js';
import Feature from './pages/feature.js';
import F0F from './pages/F0F.js';
import Testimonials from './pages/testimonials';
import Video from './components/video';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CaseStudyDetails from './components/CaseStudyDetails';
import PrivacyPolicy from './pages/privacyPolicy.js';
import TermAndCondition from './pages/term&Condition.js'

function App() {
  return <>
  <Routes>
    <Route path="/" element={<Home />} ></Route>
    <Route path="about" element={<About />} ></Route>
    <Route path="/svc" element={<Service />} ></Route>
    <Route path="/case-study" element={<Projects />} ></Route>
    <Route path="/ourteam" element={<Teamview />} ></Route>
    <Route path="/features" element={<Feature />} ></Route>
    <Route path="/F0F" element={<F0F />} ></Route>
    <Route path="/faqs" element={<Faqs />} ></Route>
    <Route path="/contact-us" element={<Contact />} ></Route>
    <Route path="/testimonial" element={<Testimonials />} ></Route>
    <Route path="/video/:id" element={<Video />} ></Route>
    <Route path="/case-study/:id" element={<CaseStudyDetails />}></Route>
    <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
    <Route path="/term-conditions" element={<TermAndCondition />} ></Route>
    
  </Routes>
  </>
}

export default App;
