import React from 'react'

function Faq() {
  return (
    <div>
        <div className="container-fluid py-5">
        <div className="container py-5">
            <div className="mx-auto text-center wow fadeIn faq" data-wow-delay="0.1s">
                <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Popular FAQs</div>
                <h1 className="mb-4">Frequently Asked Questions</h1>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="accordion" id="accordionFAQ1">
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.1s">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    What services does QFI offer?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionFAQ1">
                                <div className="accordion-body">
                                At QFI, we offer a comprehensive range of IT services, including but not limited to:<br></br>
                                1. Software development<br></br>
                                2. Web development<br></br>
                                3. Mobile app development<br></br>
                                4. Cybersecurity solutions<br></br>
                                5. Cloud computing<br></br>
                                6. IT consulting<br></br>
                                7. Digital transformation services

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.2s">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How can I request a quote or estimate for my project?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionFAQ1">
                                <div className="accordion-body">
                                Getting a quote or estimate for your project is easy! Simply contact us through our website or email us at [your contact email] with details about your project requirements, and our team will get back to you promptly with a customized quote.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.3s">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    What industries does QFI serve?

                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionFAQ1">
                                <div className="accordion-body">
                                We work with clients across various industries, including but not limited to:<br></br>
                                1. Healthcare<br></br>
                                2. Finance<br></br>
                                3. E-commerce<br></br>
                                4. Education<br></br>
                                5. Manufacturing<br></br>
                                6. Hospitality<br></br>
                                7. Startups and many more...
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.4s">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                     What sets QFI apart from other IT service providers?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                                data-bs-parent="#accordionFAQ1">
                                <div className="accordion-body">
                                At QFI, we differentiate ourselves through:<br></br>
                                1. Expertise: Our team comprises skilled professionals with extensive experience in the IT industry.<br></br>
                                2. Tailored solutions: We customize our services to meet your unique business needs.<br></br>
                                3. Cutting-edge technology: We stay updated with the latest technological advancements to deliver innovative solutions.<br></br>
                                4. Client-centric approach: We prioritize clear communication, transparency, and collaboration to ensure client satisfaction.

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="1.0s">
                            <h2 className="accordion-header" id="headingTen">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                     Does QFI offer expertise in blockchain technology?

                                </button>
                            </h2>
                            <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen"
                                data-bs-parent="#accordionFAQ1">
                                <div className="accordion-body">
                                Yes, we have experience in blockchain development and can assist with implementing blockchain solutions for various use cases, such as supply chain management, decentralized finance (DeFi), digital identity, and smart contracts.

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="1.1s">
                            <h2 className="accordion-header" id="headingEleven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                     Can QFI provide references or case studies of previous projects?

                                </button>
                            </h2>
                            <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven"
                                data-bs-parent="#accordionFAQ1">
                                <div className="accordion-body">
                                Certainly. We'd be happy to provide references or share case studies of projects that showcase our capabilities and track record of success. Just let us know your specific requirements, and we'll provide relevant information accordingly.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="accordion" id="accordionFAQ2">
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.5s">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    How does QFI ensure the security of applications and systems it develops?

                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                                data-bs-parent="#accordionFAQ2">
                                <div className="accordion-body">
                                Security is a top priority for us. We follow industry best practices and employ robust security measures throughout the development lifecycle, including:<br></br>
                                1. Conducting regular security assessments and code reviews<br></br>
                                2. Implementing secure coding practices and encryption standards<br></br>
                                3. Incorporating penetration testing and vulnerability scanning<br></br>
                                4. Providing ongoing security updates and patches
                                

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.6s">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    Can QFI assist with modernizing legacy systems and applications?
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix"
                                data-bs-parent="#accordionFAQ2">
                                <div className="accordion-body">
                                Absolutely. We have experience in modernizing legacy systems and applications, whether it involves rearchitecting monolithic applications into microservices, migrating to cloud-native environments, or implementing modern user interfaces and experiences with AI.

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.7s">
                            <h2 className="accordion-header" id="headingSeven">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    Can QFI help with implementing artificial intelligence (AI) and machine learning (ML) solutions?
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven"
                                data-bs-parent="#accordionFAQ2">
                                <div className="accordion-body">
                                Absolutely. We specialize in AI and ML development and can help you leverage these technologies to automate processes, gain insights from data, and enhance decision-making. Our expertise includes natural language processing (NLP), computer vision, recommendation systems, and predictive analytics.

                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.8s">
                            <h2 className="accordion-header" id="headingEight">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                         Can QFI assist with data security and compliance concerns?
                                </button>
                            </h2>
                            <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight"
                                data-bs-parent="#accordionFAQ2">
                                <div className="accordion-body">
                                Absolutely. We prioritize cybersecurity and compliance with industry standards and regulations. Our team can help assess your current security posture, implement robust security measures, and ensure compliance with relevant regulations such as GDPR, HIPAA, and PCI-DSS.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="1.2s">
                            <h2 className="accordion-header" id="headingTwelve">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                         How does QFI approach software testing and quality assurance?
                                </button>
                            </h2>
                            <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve"
                                data-bs-parent="#accordionFAQ2">
                                <div className="accordion-body">
                                Quality is paramount to us, and we employ rigorous software testing and quality assurance practices to ensure that our solutions meet the highest standards. Our approach includes unit testing, integration testing, regression testing, and user acceptance testing to identify and address any issues before deployment.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item wow fadeIn" data-wow-delay="0.9s">
                            <h2 className="accordion-header" id="headingNine">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                         How can I get in touch with QFI?
                                </button>
                            </h2>
                            <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine"
                                data-bs-parent="#accordionFAQ2">
                                <div className="accordion-body">
                                You can reach us by:<br></br>
                                Email: [your contact email]<br></br>
                                Phone: [your phone number]<br></br>
                                Contact form: [link to contact form on your website]
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Faq
