import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
    return(
           <div className="container-fluid sticky-top custom-container">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                <Link to="/" className="navbar-brand">
                    <img src={`/logo1-removebg-preview.png`} alt="Your Logo"></img>
                </Link>
                <button type="button" className="navbar-toggler ms-auto me-0 navbar-toggler-custom" data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto">
                       
                       <Link className="nav-item nav-link active" to={"/"} >Home</Link>
                        
                        <Link className="nav-item nav-link active" to={"/about"}>About</Link>
                        
                        <Link className="nav-item nav-link active" to={"/svc"}>Services</Link>
                        
                        <Link className="nav-item nav-link active" to={"/case-study"}>Projects</Link>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                            <div className="dropdown-menu bg-light mt-2">
                               
                                <Link className="dropdown-item" to={"/features"}>Feature</Link>
                                <Link className="dropdown-item" to={"/ourteam"}>Team</Link>
                                <Link className="dropdown-item" to={"/faqs"}>Faqs</Link>
                                <Link className="dropdown-item" to={"/testimonial"}>Testimonial</Link>
                                <Link className="dropdown-item" to={"/F0F"}>404</Link>
                                
                            </div>
                        </div>
                        <Link className="nav-item nav-link" to={"/contact-us"}>Contact</Link>
                    </div>
                    <butaton type="button" className="btn text-white p-0 d-none d-lg-block" data-bs-toggle="modal"
                        data-bs-target="#searchModal"><i className="fa fa-search"></i></butaton>
                </div>
                </nav>
            </div>
        </div> 
   ); 
}
export default Header;