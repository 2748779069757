import React from 'react'
import { Link } from 'react-router-dom'
function Hero1() {
  return (
    <div>
       <div class="container-fluid pt-5 bg-primary hero-header mb-5">
        <div class="container pt-5">
            <div class="row g-5 pt-5">
                <div class="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                     <div class="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">Quantum Fusion InfoTech </div>
                    <h1 class="display-4 text-white mb-4 animated slideInRight">Future-Ready: AI-Infused: Your Complete IT Partner </h1>
                    <p class="text-white mb-4 animated slideInRight ">Empower your business with cutting-edge AI-infused solutions tailored to 
                    meet your evolving needs. As your trusted IT partner, we're committed to keeping you ahead of the curve, ensuring seamless integration 
                    of modern technologies for optimized performance and sustained growth. Experience the future of IT with our comprehensive suite of services 
                    from our expertised and certified professionals to elevate your business to new heights.</p>
                    <Link className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight" to={"/about"} target="_blank">Read More</Link>
                    {/* <a href="service.html" target="_blank" class="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight">Read More</a> */}
                    <Link className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight" to={"/contact-us"} target="_blank">Contact Us</Link>
                    {/* <a href="contact.html" target="_blank" class="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a> */}
                </div>
                <div class="col-lg-6 align-self-end text-center text-lg-end">
                    <img class="img-fluid" src="img/hero-img.png" alt=""></img>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Hero1
